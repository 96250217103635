import ModalFooterButton from "@/components/common/ModalFooterButton";
import Notification from "@/components/common/Notification";
import SelectInput from "@/components/form/SelectInput";
import TextInput, { messageError } from "@/components/form/TextInput";
import { nopeResolver } from "@hookform/resolvers/nope";
import { API_URL } from "@/redux-client/api";
import { agentStatusOptions } from "@/static/data";
import axios from "axios";
import Nope from "nope-validator";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import MessageError from "../form/MessageError";

export const schemaAgent = Nope.object().shape({
  email: Nope.string()
    .email("Masukkan email dengan benar!")
    .required("Masukkan email wajib!"),
  name: Nope.string().required("Masukkan nama wajib!"),
  phone: Nope.string().required("Masukkan no. hp wajib!"),
  bank_name: Nope.string().required("Masukkan nama bank!"),
  account_number: Nope.string().required("Masukkan nomor rekening!")
});

function FormAgent({
  type = "add",
  dataFields,
  disabled = false,
  loading = false,
  className = "",
  closeModalHandler,
  submitHandler,
	contractFiles,
	onChangeFiles
}) {
  const {
    register,
    control,
    setValue,
    handleSubmit,
		setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
      bank_name: "",
      account_number: "",
      status: null,
			contract_file: null
    },
    resolver: nopeResolver(schemaAgent),
  });

  useEffect(() => {
    if (dataFields) {
      setValue("email", dataFields.email);
      setValue("name", dataFields.name);
      setValue("phone", dataFields.phone);

			if(dataFields.bank_data) {
				setValue("bank_name", dataFields.bank_data.bank_name);
				setValue("account_number", dataFields.bank_data.account_number);
			}

      const accountStatus = agentStatusOptions.find(
        (el) => el.value === dataFields.status
      );
      setValue("status", accountStatus);
    }
  }, [dataFields, setValue]);

  return (
    <form>
      <div className={`grid grid-cols-1 gap-4 ${className}`}>
        <div
          className={`border-b border-t bg-orange-600 text-white px-4 rounded-md ${
            type === "edit" || type === "verify" ? "col-span-3" : ""
          }`}
        >
          <h3 className="text-sm font-semibold py-2">Informasi Akun</h3>
        </div>
        <TextInput label="Email" error={errors?.email}>
          <input
            type="email"
            {...register("email")}
            disabled={loading || disabled || type === "edit" || type === "verify"}
            aria-invalid={!!errors?.email}
          />
        </TextInput>
        <TextInput label="Nama" error={errors?.name}>
          <input
            type="text"
            {...register("name")}
            disabled={loading || disabled}
            aria-invalid={!!errors?.name}
          />
        </TextInput>
        <TextInput label="No. HP" error={errors?.phone}>
          <input
            type="tel"
            {...register("phone")}
            disabled={loading || disabled}
            aria-invalid={!!errors?.phone}
          />
        </TextInput>
        {type === "edit" && (
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <SelectInput
                label="Status Akun"
                disabled={loading || disabled}
                options={agentStatusOptions}
                value={value}
                error={errors?.status}
                onChange={onChange}
              />
            )}
          />
        )}
        <div
          className={`mt-4 border-b border-t bg-orange-600 text-white px-4 rounded-md ${
            type === "edit" || type === "verify" ? "col-span-3" : ""
          }`}
        >
          <h3 className="text-sm font-semibold py-2">
            Informasi Rekening Pencairan Komisi
          </h3>
        </div>
        <TextInput label="Nama Bank" error={errors?.bank_name}>
          <input
            type="text"
            {...register("bank_name")}
            disabled={loading || disabled}
            aria-invalid={!!errors?.bank_name}
          />
        </TextInput>
        <TextInput label="Nomor Rekening" error={errors?.account_number}>
          <input
            type="text"
            {...register("account_number")}
            disabled={loading || disabled}
            aria-invalid={!!errors?.account_number}
          />
        </TextInput>
      </div>
      <div
        className={`mt-8 border-b border-t bg-orange-600 text-white px-4 rounded-md ${
          type === "edit" || type === "verify" ? "col-span-3" : ""
        }`}
      >
        <h3 className="text-sm font-semibold py-2">
          Informasi Dokumen Perjanjian
        </h3>
      </div>
      {contractFiles.length > 0 ? (
				<div className="mt-5">
					<label className="text-sm font-semibold ml-1 mb-2 px-1">Dokumen Perjanjian</label> 
					<div className="flex items-start justify-between mt-1 px-0 gap-5">
						<input
							disabled
							readOnly
							value={contractFiles[0].name}
							className="flex-1 py-2 rounded-xl px-4"
						/>
						<div className="flex gap-4">
							<a
								alt=""
								href={URL.createObjectURL(contractFiles[0])}
								target="_blank"
								rel="noreferrer noopener"
								className="px-4 py-2 border-2 text-sm text-orange-700 border-orange-700 hover:bg-orange-600 hover:text-white transition-all duration-300 rounded-full"
							>
								Lihat Dokumen
							</a>
							<button
								onClick={() => onChangeFiles([])}
								className="px-4 py-2 border-2 border-orange-600 bg-orange-600 text-white text-sm hover:bg-orange-700 hover:border-orange-700 transition-all duration-300 rounded-full"
							>
								Upload Ulang Dokumen
							</button>
						</div>
					</div>
				</div>
      ) : (
        <div className=" items-center justify-center w-full mt-5">
          <label
            htmlFor="dropzone-file"
            className={`flex flex-col items-center justify-center w-full h-50px border-2 border-dashed rounded-lg cursor-pointer transition-all duration-300 ${
              errors.contract_file ? "border-red-300 bg-red-50 hover:bg-red-100" : "border-gray-300 bg-gray-50 hover:bg-gray-100"
            }`}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Klik untuk Pilih File</span>
              </p>
              <p className="text-xs text-gray-500">Format File : PDF</p>
              <p className="text-xs text-gray-500">Ukuran File : max. 4 MB</p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              accept="application/pdf"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  if (e.target.files[0].size > 4 * 1000 * 1024) {
										setError('contract_file', {
											message: 'Ukuran file terlalu besar. Maksimal 4 MB'
										});
                    return false;
                  } else {
										setError('contract_file', null)
                    onChangeFiles(e.target.files);
                  }
                }
              }}
            />
          </label>
					{errors.contract_file ? 
						<MessageError error={messageError(errors.contract_file.message)} /> 
					: null }
        </div>
      )}
      {!disabled ? (
        <ModalFooterButton
          loading={loading}
          onSubmit={handleSubmit(submitHandler)}
          onCancel={closeModalHandler}
        />
      ) : null}
    </form>
  );
}

export function AddFormAgent({ className = "", admin_id, closeModalHandler }) {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const [contractFiles, setContractFiles] = useState([]);

  const uploadFile = async ({ files, file_name }) => {
    if (files.length === 0) {
      return undefined;
    } else {
      const formData = new FormData();

      const fileType = files[0]["type"].split("/");
      const ext = fileType[fileType.length - 1];
      const filename = `${file_name}.${ext}`;

      formData.append("contract_file", files[0], filename);

      const response = await axios.post(
        `${API_URL}/upload/contract-file`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const { status, data } = response;
      if (status !== 200) return null;
      return data.data.filename;
    }
  };

  const submitCreateNewAgent = async (payload) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}/agent/create-and-verify`, payload);
      if (response.status === 200) {
        const { message } = response.data;

        setNotification({
          isError: false,
          title: "Sukses",
          text: message,
        });

        setIsLoading(false);

        setTimeout(() => {
          setNotification(null);
					closeModalHandler();
        }, 3000);
      } else {
        const { message } = response.data;
        setIsLoading(false);
        setNotification({
          isError: true,
          title: "Error",
          text: message,
        });
      }
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        const { message } = error.response.data;
        setNotification({
          isError: true,
          title: "Error",
          text: message,
        });
      }

      setIsLoading(false);

      setTimeout(() => {
        setNotification(null);
      }, 2000);
    }
  };

  const onSubmit = useCallback(
    async (data) => {
      const payload = {
        ...data,
				bank_data: {
					bank_name: data.bank_name,
					account_number: data.account_number
				},
        isVerified: true,
        status: "verified",
        verified_date: moment().format("DD/MM/YYYY"),
        verified_by: admin_id,
      };

      const contractFilename = await uploadFile({
        files: contractFiles,
        file_name: `${payload.name.toLowerCase().split(" ").join("_")}-${moment().format("DD-MM-YYYY")}`,
      });

      if (!contractFilename) {
				setNotification({
					isError: true,
          title: "Internal server error",
          text: "Gagal mengupload dokumen perjanjian. Mohon coba beberapa saat lagi",
				})
        return undefined;
      } else {
        payload["contract_file"] = contractFilename;
        submitCreateNewAgent(payload);
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [admin_id, contractFiles]
  );

  return (
    <>
      <FormAgent
        loading={isLoading}
        className={className}
        closeModalHandler={closeModalHandler}
				contractFiles={contractFiles}
				onChangeFiles={(value) => setContractFiles(value)}
        submitHandler={onSubmit}
      />
			{notification ? (
        <div className="mb-5">
          <Notification notification={notification} />
        </div>
      ) : null}
    </>
  );
};

export function EditFormAgent({
  data,
  disabled = false,
  className = "",
  closeModalHandler,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(null);

	const [contractFiles, setContractFiles] = useState([]);

  // const uploadFile = async ({ files, file_name }) => {
  //   if (files.length === 0) {
  //     return undefined;
  //   } else {
  //     const formData = new FormData();

  //     const fileType = files[0]["type"].split("/");
  //     const ext = fileType[fileType.length - 1];
  //     const filename = `${file_name}.${ext}`;

  //     formData.append("contract_file", files[0], filename);

  //     const response = await axios.post(
  //       `${API_URL}/upload/contract-file`,
  //       formData,
  //       {
  //         headers: { "Content-Type": "multipart/form-data" },
  //       }
  //     );

  //     const { status, data } = response;
  //     if (status !== 200) return null;
  //     return data.data.filename;
  //   }
  // };

  const onSubmit = useCallback(
    async (dataForm) => {
      try {
        setIsLoading(true);
        const payload = {
          ...dataForm,
          role: dataForm.role?.value,
        };
        const response = await axios.post(`${API_URL}/agent/edit`, payload);
        if (response.status === 200) {
          const { message } = response.data;

          setNotification({
            isError: false,
            title: "Sukses",
            text: message,
          });

          setIsLoading(false);

          setTimeout(() => {
            setNotification(null);

            if (typeof closeModalHandler === "function") {
              closeModalHandler();
            }
          }, 2000);
        }
      } catch (error) {
        console.log("error", error);
        if (error?.response?.data) {
          const { message } = error.response.data;
          setNotification({
            isError: true,
            title: "Error",
            text: message,
          });
        }

        setIsLoading(false);

        setTimeout(() => {
          setNotification(null);
        }, 2000);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModalHandler]
  );

  return (
    <>
      {notification ? (
        <div className="mb-5">
          <Notification notification={notification} />
        </div>
      ) : null}
      <FormAgent
        type="edit"
        dataFields={data}
        disabled={disabled}
        loading={isLoading}
        className={className}
        closeModalHandler={closeModalHandler}
				contractFiles={contractFiles}
				onChangeFiles={(value) => setContractFiles(value)}
        submitHandler={onSubmit}
      />
    </>
  );
};

export function VerificationFormAgent({
  data,
  disabled = false,
  className = "",
	admin_id,
  closeModalHandler,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(null);

	const [contractFiles, setContractFiles] = useState([]);

  const uploadFile = async ({ files, file_name }) => {
    if (files.length === 0) {
      return undefined;
    } else {
      const formData = new FormData();

      const fileType = files[0]["type"].split("/");
      const ext = fileType[fileType.length - 1];
      const filename = `${file_name}.${ext}`;

      formData.append("contract_file", files[0], filename);

      const response = await axios.post(
        `${API_URL}/upload/contract-file`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const { status, data } = response;
      if (status !== 200) return null;
      return data.data.filename;
    }
  };

	const onVerifyAccount = async(payload) => {
		try {
			setIsLoading(true);
			const response = await axios.post(`${API_URL}/agent/auth/verify-account`, payload);
			if (response.status === 200) {
				const { message } = response.data;

				setNotification({
					isError: false,
					title: "Sukses",
					text: message,
				});

				setIsLoading(false);

				setTimeout(() => {
					setNotification(null);

					if (typeof closeModalHandler === "function") {
						closeModalHandler();
					}
				}, 2000);
			}
		} catch (error) {
			console.log("error", error);
			if (error?.response?.data) {
				const { message } = error.response.data;
				setNotification({
					isError: true,
					title: "Error",
					text: message,
				});
			}

			setIsLoading(false);

			setTimeout(() => {
				setNotification(null);
			}, 2000);
		}
	};

  const onSubmit = useCallback(
    async (dataForm) => {
			const payload = {
				email: dataForm.email,
				name: dataForm.name,
				phone: dataForm.phone,
				bank_data: {
					bank_name: dataForm.bank_name,
					account_number: dataForm.account_number,
				},
				admin_id,
			};

			const contractFilename = await uploadFile({
        files: contractFiles,
        file_name: `${payload.name.toLowerCase().split(" ").join("_")}-${moment().format("DD-MM-YYYY")}`,
      });

      if (!contractFilename) {
				setNotification({
					isError: true,
          title: "Internal server error",
          text: "Gagal mengupload dokumen perjanjian. Mohon coba beberapa saat lagi",
				})
        return undefined;
      } else {
        payload["contract_file"] = contractFilename;
        onVerifyAccount(payload);
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [admin_id, contractFiles]
  );

  return (
    <>
      {notification ? (
        <div className="mb-5">
          <Notification notification={notification} />
        </div>
      ) : null}
      <FormAgent
        type="verify"
        dataFields={data}
        disabled={disabled}
        loading={isLoading}
        className={className}
        closeModalHandler={closeModalHandler}
				contractFiles={contractFiles}
				onChangeFiles={(value) => setContractFiles(value)}
        submitHandler={onSubmit}
      />
    </>
  );
};