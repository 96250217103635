import TableAgentComission from '@/components/agents/TableAgentComission'
import React from 'react'

const AgentComissionsPage = () => {
  return (
    <div className='bg-gray-100'>
      <div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Komisi Agent</h1>
			</div>

      <TableAgentComission />
    </div>
  )
}

export default AgentComissionsPage