import { FaMoneyCheckDollar } from "react-icons/fa6";
import { BsGraphUpArrow } from "react-icons/bs";
import { HiUsers } from "react-icons/hi2";
import { Link } from "react-router-dom";

const LinkCard = ({
  title,
  icon,
  href,
}) => {
  return (
    <Link 
      className="flex flex-col items-center justify-center h-[250px] text-2xl font-semibold gap-4 bg-white rounded-xl hover:bg-gray-50"
      to={href}
    >
      {icon}
      {title}
    </Link>
  );
};

const AgentsPage = () => {
	return (
		<div className='bg-gray-100'>
			<div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Dashboard Agent</h1>
			</div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-10">
        <LinkCard 
          title="Daftar Agent"
          icon={<HiUsers size={40} />}
          href="/agents/list"
        />
        <LinkCard 
          title="Penjualan Agent"
          icon={<BsGraphUpArrow size={40} />}
          href="/agents/sales"
        />
        <LinkCard 
          title="Komisi Agent"
          icon={<FaMoneyCheckDollar size={40} />}
          href="/agents/comission"
        />
      </div>
		</div>
	);
};

export default AgentsPage;
