import React, { useState } from "react";
import SelectInput from "../form/SelectInput";
import { TextInputDefault } from "../form/TextInput";
import DatepickerInput from "../form/DatepickerInput";
import { currencyInputSeparator, rupiahFormat } from "@/helper";
import Button from "../common/Button";
import DataTextDisplay from "../common/DataTextDisplay";
import moment from "moment";
import { isEmpty } from "validate.js";
import Notification from "../common/Notification";
import { API_URL } from "@/redux-client/api";
import axios from "axios";
import { useSelector } from "react-redux";

const TRANSACTION_TYPE_OPTIONS = [
  { label: "DP", value: "dp" },
  { label: "Lunas", value: "settlement" },
];

const PaymentConfirmationForm = ({ onClose, data }) => {
  
  const { user } = useSelector((state) => state.auth);

  const {
    _id,
    agent_id,
    estimated_total_price,
    createdDate,
    event_data,
    status,
    actual_total_price,
    payment
  } = data;

  
  const totalPrice = estimated_total_price;
  
  const dp_transaction = payment.find(el => el.transaction_purpose === 'dp');
  
  const minDate = status === 'dp' ? new Date(dp_transaction.transaction_date_iso) : new Date(createdDate);
  const maxDate = new Date(moment(event_data.event_date, "DD/MM/YYYY").format("MM/DD/YYYY"));

  let transaction_purpose = status === 'negosiasi' ? {
    label: "DP",
    value: "dp",
  } : { label: "Lunas", value: "settlement" };

  let total_payment;
  if(status === 'dp') total_payment = currencyInputSeparator(actual_total_price-dp_transaction.total_amount);

  const [transactionPurpose, setTransactionPurpose] = useState(transaction_purpose);
  const [totalPayment, setTotalPayment] = useState(total_payment);
  const [actualTotalPrice, setActualTotalPrice] = useState(currencyInputSeparator(actual_total_price));
  const [transactionDate, setTransactionDate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [notification, setNotification] = useState(null);

  const isValid = () => {
    let errors = {};
    
    if(!actualTotalPrice) errors['actualTotalPrice'] = 'Mohon masukkan harga deal';
    if(!transactionDate) errors['transactionDate'] = 'Mohon pilih tanggal pembayaran';
    if(!totalPayment) errors['totalPayment'] = 'Mohon masukkan jumlah pembayaran';

    setErrors(errors);

    return isEmpty(errors);
  };

  const onSubmit = async() => {
    setIsLoading(true);
    setNotification(null);

    if(isValid()) {
      const total_amount = Number(totalPayment.split('.').join(''));
      const actual_total_price = Number(actualTotalPrice.split('.').join(''));
  
      const payload = {
        transaction_purpose: transactionPurpose.value,
        transaction_date: transactionDate.startDate,
        total_amount,
        actual_total_price,
        commission: total_amount*agent_id.percent_comission/100,
        agent_id: agent_id._id,
        sales_id: _id,
        admin_id: user._id
      };
      
      try {
        const response = await axios.post(
          `${API_URL}/booking/payment-confirmation`,
          payload
        );

        if (response.status === 200) {
          // const { data } = response.data;
          setNotification({
            isError: false,
            title: "Sukses",
            text: "Pembayaran berhasil dikonfirmasi"
          });
          setTimeout(() => {
            setIsLoading(false);
            onClose();
          }, 3000);
        };
      } catch (error) {
        if (error?.response?.data) {
          const { message } = error.response.data;
          setNotification({
            isError: true,
            title: "Gagal menyimpan data. Mohon coba beberapa saat lagi.",
            text: message,
          });
        }
  
        setIsLoading(false);
  
        setTimeout(() => {
          setNotification(null);
        }, 3000);
      }
    } else {
      setIsLoading(false);
      setNotification({
        isError: true,
        title: "Terjadi Kesalahan",
        text: "Silahkan lengkapi form di atas dengan benar",
      });

      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  return (
    <div className="border-t">
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 border-b pb-4">
        { status === "negosiasi" ? (
          <DataTextDisplay 
            label="Total Estimasi Harga"
            value={rupiahFormat(totalPrice)}
          />
        ) : (
          <>
            <DataTextDisplay 
              label="Total Tagihan"
              value={rupiahFormat(actual_total_price)}
            />
            <DataTextDisplay 
              label="DP"
              value={rupiahFormat(dp_transaction.total_amount)}
            />
            <DataTextDisplay 
              label="Sisa Pembayaran"
              value={rupiahFormat(actual_total_price-dp_transaction.total_amount)}
            />
            <DataTextDisplay 
              label="Tambahan Komisi Agent"
              value={totalPayment ? rupiahFormat(Number(totalPayment.split('.').join(''))*agent_id.percent_comission/100) : 'Rp 0'}
            />
          </>
        )}
      </div>
      { status === "negosiasi" && (
        <div className="mt-4">
          <TextInputDefault
            name="actualTotalPrice"
            label="Harga Deal"
            value={actualTotalPrice}
            placeholder="Masukkan harga nego"
            onChange={({ target }) => {
              setErrors({
                ...errors,
                [target.name]: null,
              });
              if (target.value) {
                const currencyValue = currencyInputSeparator(target.value);
                setActualTotalPrice(currencyValue);
              } else {
                setTotalPayment();
              };
            }}
            disabled={isLoading}
            error={errors.actualTotalPrice}
          />
        </div>
      )}
      <div className="mt-4">
        <SelectInput
          label="Jenis Pembayaran"
          disabled={isLoading || status === 'dp'}
          options={TRANSACTION_TYPE_OPTIONS}
          value={transactionPurpose}
          error={""}
          onChange={(val) => setTransactionPurpose(val)}
        />
      </div>
      <div className="mt-4">
        <DatepickerInput
          name="transactionDate"
          primaryColor="orange"
          minDate={minDate}
          maxDate={maxDate}
          onChange={(val) => {
            setErrors({
              ...errors,
              transactionDate: null,
            });
            setTransactionDate(val);
          }}
          value={transactionDate}
          label="Tanggal Pembayaran"
          disabled={isLoading}
          error={errors?.transactionDate}
        />
      </div>
      <div className="mt-4">
        <TextInputDefault
          name="totalPayment"
          label="Jumlah Pembayaran"
          // type="number"
          value={totalPayment}
          placeholder="Masukkan jumlah"
          onChange={({ target }) => {
            setErrors({
              ...errors,
              [target.name]: null,
            });
            if (target.value) {
              const currencyValue = currencyInputSeparator(target.value);
              setTotalPayment(currencyValue);
            } else {
              setTotalPayment();
            };
          }}
          disabled={isLoading || status === 'dp'}
          error={errors.totalPayment}
        />
      </div>

      { status === 'negosiasi' ?
        <div className="border-b mt-4 pb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <DataTextDisplay 
            label="Sisa Pembayaran"
            value={!actualTotalPrice ? rupiahFormat(estimated_total_price) : actualTotalPrice && totalPayment ? rupiahFormat(Number(actualTotalPrice.split('.').join('')) - Number(totalPayment.split('.').join(''))) : `Rp ${actualTotalPrice}`}
          />
          <DataTextDisplay 
            label="Komisi Agent"
            value={totalPayment ? rupiahFormat(Number(totalPayment.split('.').join(''))*agent_id.percent_comission/100) : 'Rp 0'}
          />
        </div>
      : null }

      <Notification notification={notification} />

      <div className="mt-5 text-end">
        <Button onClick={onSubmit}>
          Konfirmasi
        </Button>
      </div>
    </div>
  );
};

export default PaymentConfirmationForm;
