import SalesEditForm from "@/components/agents/SalesEditForm";
import BookingDetails from "@/components/booking/BookingDetails";
import Button from "@/components/common/Button";
import Loading from "@/components/common/Loading";
import Notification from "@/components/common/Notification";
import { API_URL } from "@/redux-client/api";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

const AgentSalesDetailsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [salesData, setSalesData] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [pageNotification, setPageNotification] = useState(null);

  const fetchSalesDetails = useCallback(async () => {
    setPageLoading(true);

    try {
      const response = await axios.get(`${API_URL}/booking/${state._id}`);
      if (response.status === 200) {
        const { message } = response.data;
        setSalesData(message.data);
        setPageLoading(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data;
        setPageNotification({
          isError: true,
          title: "Error",
          text: message,
        });
      }

      setPageLoading(false);

      setTimeout(() => {
        setPageNotification(null);
      }, 2000);
    };
  }, [state]);

  useEffect(() => {
    fetchSalesDetails();
  }, [fetchSalesDetails]);

  return (
    <div>
      <div className="bg-white text-center w-full py-5 rounded-lg relative">
        <div className="absolute ml-3">
          <button
            onClick={() => navigate("/agents/sales")}
            className="flex items-center rounded-xl text-black opacity-80 hover:opacity-100 transition-all ease-in-out duration-300"
          >
            <HiChevronLeft size={20} />
            Kembali
          </button>
        </div>
        <h1 className="text-2xl font-bold">Detail Pemesanan</h1>
      </div>
      <Notification notification={pageNotification} />
      <div>
        {pageLoading ? (
          <div className="py-10">
            <Loading type="beat-loader" loading={pageLoading} size={24} />
          </div>
        ) : (
          <>
            { salesData ? 
              <SalesEditForm salesData={salesData} />
            : null }
          </>
        )}
      </div>
    </div>
  );
};

export default AgentSalesDetailsPage;
