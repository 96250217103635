import React, { useState } from "react";
import DataTextDisplay from "../common/DataTextDisplay";
import moment from "moment";
import { rupiahFormat } from "@/helper";
import Button from "../common/Button";
import Modal from "../common/Modal";
import PaymentConfirmationForm from "./PaymentConfirmationForm";

const SubTitleText = ({ label }) => {
  return (
    <div className=" bg-slate-600 px-5 py-3 flex justify-between items-center">
      <h2 className="font-bold text-white">{label}</h2>
    </div>
  );
};

const SalesEditForm = ({ salesData }) => {

  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const onCloseConfirmationModal = () => {
    setIsOpenConfirmationModal(false);
    window.location.reload();
  };

  return (
    <>
      <div className="mt-8">
        <div className="mb-8 text-sm bg-orange-500 text-white text-center px-5 py-4 rounded-xl">
          <p>Untuk perubahan data pemesanan hanya bisa dilakukan oleh Agen yang bersangkutan melalui Portal Agen.</p>
        </div>

        <div className="flex items-center justify-between">
          <div className="bg-white inline-flex px-5 py-5 rounded-2xl">
            <p>
              Status Pesanan :
              <label 
                className={`ml-2 px-4 text-sm py-2 capitalize font-semibold rounded-2xl ${salesData.status === "deal" ? "bg-green-600" : salesData.status === "cancel" ? "bg-red-500" : "bg-amber-400"}`}
              >
                {salesData.status}
              </label>
            </p>
          </div>
          { salesData.status === "negosiasi" ? 
            <Button 
              onClick={() => setIsOpenConfirmationModal(true)}
            >
              Konfirmasi Pembayaran
            </Button>
          : salesData.status === "dp" ? 
            <Button 
              onClick={() => setIsOpenConfirmationModal(true)}
            >
              Konfirmasi Pelunasan
            </Button>
          : null }
        </div>

        <div className="bg-white rounded-xl overflow-hidden mt-8">
          <SubTitleText label="Rincian Pesanan" />
          <div className="p-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              <DataTextDisplay
                label="Kode Booking"
                value={salesData.booking_code}
              />
              <DataTextDisplay
                label="Nama Customer"
                value={salesData.customer_id.name}
              />
              <DataTextDisplay
                label="Email Customer"
                value={salesData.customer_id.email}
              />
              <DataTextDisplay
                label="No. HP Customer"
                value={salesData.customer_id.phone}
              />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-5">
              <DataTextDisplay
                label="Jenis Acara"
                value={salesData.event_data.event_type}
              />
              <DataTextDisplay
                label="Tanggal Acara"
                value={`${moment(
                  salesData.event_data.event_date,
                  "DD/MM/YYYY"
                ).format("DD MMMM YYYY")} - ${salesData.event_data.event_time}`}
              />
              <DataTextDisplay
                label="Lokasi Acara"
                value={salesData.event_data.event_location}
              />
              <DataTextDisplay
                label="Keterangan"
                value={salesData.event_data.event_note || "-"}
              />
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-xl overflow-hidden mt-8">
          <SubTitleText label="Informasi Paket & Pilihan Menu" />
          <div className="p-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              <DataTextDisplay
                label="Nama Paket"
                value={`- ${salesData.order_items.main_package.package_name}` || "-"}
              />
              <DataTextDisplay
                label="Total pax"
                value={
                  `${salesData.order_items.main_package.total_pax} pax` || "-"
                }
              />
            </div>
            { salesData.order_items.additional_packages.length > 0 ? 
              salesData.order_items.additional_packages.map((pckg, idx) => {
                return (
                  <div key={`additional_${idx}`} className="grid grid-cols-2 md:grid-cols-4 gap-3">
                    <DataTextDisplay
                      value={`- ${pckg.package_name}` || "-"}
                    />
                    <DataTextDisplay
                      value={`${pckg.total_pax} pax` || "-"}
                    />
                  </div>
                )
              })
            : null }
            <hr className="my-3" />
            <div>
              <label className="text-sm">Pilihan Menu Paket Utama</label>
              <ul className="mt-1">
                {salesData.order_items.main_package.selected_menus.map((item, idx) => {
                  return (
                    <li key={`menuType_${idx}`} className="capitalize text-sm">
                      - {item.menuType} : <strong>{item.menuTypeVariations.join(', ')}</strong>
                    </li>
                  )
                })}
              </ul>
              <p className="text-md mt-1 font-semibold">{}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl overflow-hidden mt-8">
          <SubTitleText label="Kontak Agen" />
          <div className="p-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              <DataTextDisplay
                label="Nama Agen"
                value={salesData.agent_id.name}
              />
              <DataTextDisplay
                label="Email Agen"
                value={salesData.agent_id.email}
              />
              <DataTextDisplay
                label="No. HP Agen"
                value={salesData.agent_id.phone}
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl overflow-hidden mt-8">
          <SubTitleText label="Rincian Total Harga" />
          <div className="p-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              <DataTextDisplay
                label="Nama Paket"
                value={`- ${salesData.order_items.main_package.package_name}` || "-"}
              />
              <DataTextDisplay
                label="Harga per pax"
                value={`${rupiahFormat(salesData.order_items.main_package.price_per_pax)}` || "-"}
              />
              <DataTextDisplay
                label="Total pax"
                value={
                  `${salesData.order_items.main_package.total_pax} pax` || "-"
                }
              />
              <DataTextDisplay
                label="Estimasi Harga"
                value={`${rupiahFormat(salesData.order_items.main_package.subtotal_price)}` || "-"}
              />
            </div>
            { salesData.order_items.additional_packages.length > 0 ? 
              salesData.order_items.additional_packages.map((pckg, idx) => {
                return (
                  <div key={`additional_package-${idx}`} className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-[1px]">
                    <DataTextDisplay
                      value={`- ${pckg.package_name}` || "-"}
                    />
                    <DataTextDisplay
                      value={`${rupiahFormat(pckg.price_per_pax)}` || "-"}
                    />
                    <DataTextDisplay
                      value={
                        `${pckg.total_pax} pax` || "-"
                      }
                    />
                    <DataTextDisplay
                      value={`${rupiahFormat(pckg.subtotal_price)}` || "-"}
                    />
                  </div>
                );
              })
            : null }
            <hr className="mt-3"/>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
              <div className="col-span-3">
                <p className="text-end pr-4">Biaya Tambahan</p>
              </div>
              <div>
                <p className="text-md font-semibold">{rupiahFormat(salesData.extra_order_fee)}</p>
              </div>
            </div>
            <hr className="mt-3"/>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
              <div className="col-span-3">
                <p className="text-end pr-4 font-semibold">Estimasi Total Tagihan</p>
              </div>
              <div>
                <p className="text-md font-semibold">{rupiahFormat(salesData.estimated_total_price)}</p>
              </div>
            </div>
            <hr className="mt-3"/>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
              <div className="col-span-3">
                <p className="text-end pr-4 font-semibold">Estimasi Komisi Agen</p>
              </div>
              <div>
                <p className="text-md font-semibold">{rupiahFormat(salesData.estimated_total_price*salesData.agent_id.percent_comission/100)}</p>
              </div>
            </div>
          </div>
        </div>

        { salesData.payment.length > 0 ? 
          <div className="bg-white rounded-xl overflow-hidden mt-8">
            <SubTitleText label="Rincian Transaksi & Komisi" />
            <div className="p-5">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <DataTextDisplay label="Harga Deal" value={rupiahFormat(salesData.actual_total_price)} />
                <DataTextDisplay label="Total Komisi" value={rupiahFormat(salesData.actual_total_price*salesData.agent_id.percent_comission/100)} />
              </div>
              <hr className="my-3" />
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-start pb-1">Jenis Transaksi</th>
                    <th className="text-start pb-1">Jumlah Transaksi</th>
                    <th className="text-start pb-1">Tanggal Transaksi</th>
                    <th className="text-start pb-1">Komisi</th>
                  </tr>
                </thead>
                <tbody>
                  {salesData.payment.map((item, idx) => {
                    return (
                      <tr key={`payment_row_${idx}`} className="">
                        <td className="text-start py-1 capitalize">{item.transaction_purpose === 'settlement' ? 'Pelunasan' : item.transaction_purpose}</td>
                        <td className="text-start py-1">{rupiahFormat(item.total_amount)}</td>
                        <td className="text-start py-1">{moment(item.transaction_date, "YYYY-MM-DD").format("DD MMMM YYYY")}</td>
                        <td className="text-start py-1">{rupiahFormat(item.commission)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        : null }
      </div>
      <Modal
        title={salesData.status === 'negosiasi' ? 'Konfirmasi Pembayaran' : 'Konfirmasi Pelunasan'}
        isOpen={isOpenConfirmationModal}
        showCloseButton={true}
        onClose={onCloseConfirmationModal}
      >
        <PaymentConfirmationForm 
          data={salesData}
          onClose={onCloseConfirmationModal} 
        />
      </Modal>
    </>
  );
};

export default SalesEditForm;
