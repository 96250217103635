import TableSalesAgent from '@/components/agents/TableSalesAgent'
import React from 'react'

const AgentSalesPage = () => {
  return (
    <div className='bg-gray-100'>
      <div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Daftar Penjualan Agent</h1>
			</div>

      <TableSalesAgent />
    </div>
  )
}

export default AgentSalesPage