import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CustomTable from '../table/CustomTable';
import Notification from '../common/Notification';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '@/redux-client/api';
// import Button from '../common/Button';
// import { HiPencilAlt } from 'react-icons/hi';
// import TableFilterSelect from '../common/TableFilterSelect';
import { rupiahFormat } from '@/helper';
import moment from 'moment';

const TableTransactionHistory = ({ agentId }) => {
  const navigate = useNavigate();
	const { pathname } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);
	const [pages, setPages] = useState(0);
	const [data, setData] = useState([]);
	const [tableKey, setTableKey] = useState(null);
  
  const tableRef = useRef(null);
  // const scrollToTable = () => tableRef.current.scrollIntoView();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setTableKey(Date.now());

		return () => setTableKey(Date.now());
	}, []);

	const fetchTransactions = useCallback(async (page, filtered, sorted, pageSize) => {
		setIsLoading(true);
		const payload = {
      agentId,
			pagination: {
				limit: pageSize,
				activePage: page,
			},
			sort: sorted,
			filters: filtered,
		};

		try {
			const response = await axios.post(`${API_URL}/transaction/list`, payload);
      const { status, message } = response.data;
      
			if (status === 200) {
				const { data, totalPage } = message;
				setData(data);
				setPages(totalPage);
				setIsLoading(false);
			} else {
				const { statusText } = response;
				setNotification({
					title: statusText,
					text: 'Please try again later.',
					isError: true,
				});
			}
		} catch (error) {
			console.log(error);
			setNotification({
				title: 'Network error!',
				text: 'Please try again later.',
				isError: true,
			});
		}
	}, [agentId]);

  const tableColumns = useMemo(
		() => [
			{
				Header: 'Booking ID',
				accessor: 'sales_id.booking_code',
				minWidth: 160,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm'>{value}</p>;
				},
			},
			{
				Header: 'Jenis Transaksi',
				accessor: 'transaction_purpose',
				minWidth: 140,
				textAlign: 'left',
				sortable: false,
        filterable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm capitalize'>{value}</p>;
				},
			},
      {
				Header: 'Tanggal Transaksi',
				accessor: 'transaction_date',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
        filterable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm'>{moment(value, "YYYY-MM-DD").format("DD MMMM YYYY")}</p>;
				},
			},
			{
				Header: 'Jumlah Transaksi',
				accessor: 'total_amount',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
        filterable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm'>{rupiahFormat(value)}</p>;
				},
			},
			{
				Header: 'Jumlah Komisi',
				accessor: 'commission',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
        filterable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm'>{rupiahFormat(value)}</p>;
				},
			},
			// {
			// 	Header: '',
			// 	accessor: '_id',
			// 	minWidth: 180,
			// 	filterable: false,
			// 	sortable: false,
			// 	Cell: ({ row }) => {
      //     return (
			// 			<div className='flex items-center justify-between'>
			// 				<Button
			// 					buttonType='info'
			// 					buttonSize='sm'
			// 					rounded={false}
			// 					onClick={() =>
			// 						navigate(`${pathname}/details`, {
			// 							state: row._original,
			// 						})
			// 					}
			// 				>
			// 					<HiPencilAlt
			// 						size={14}
			// 						className='mr-1'
			// 					/>
			// 					Lihat Detail
			// 				</Button>
			// 			</div>
			// 		)
			// 	},
			// 	style: { textAlign: 'center' },
			// },
		],
		[]
	);

  return (
    <>
			<Notification notification={notification} />
			<CustomTable
        ref={tableRef}
				wrapperClassName='p-4'
				pages={pages}
				tableKey={tableKey}
				isLoading={isLoading}
				data={data}
				columns={tableColumns}
				onFetchData={({ page, filtered, sorted, pageSize }) => {
					fetchTransactions(page, filtered, sorted, pageSize);
				}}
				showTableHeader={false}
			/>
		</>
  )
}

export default TableTransactionHistory;