import React from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronBack } from 'react-icons/io5';
import Notification from "@/components/common/Notification";
import { useState } from "react";
import { AddFormAgent } from "@/components/agents/FormAgent";
import { useSelector } from "react-redux";

const AddAgentPage = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [notification, setNotification] = useState(null);

  return (
    <div>
      <div className="bg-white text-center w-full py-5 rounded-lg relative">
        <h1 className="text-2xl font-bold">Tambah Agent Baru</h1>
        <button
          onClick={() => navigate("/agents/list")}
          className="absolute left-[10px] top-[29%]"
        >
          <IoChevronBack size={30} />
        </button>
      </div>
      <Notification notification={notification} />
      <div className="bg-white p-5 mt-10">
			  <AddFormAgent 
          admin_id={user._id}
          closeModalHandler={() => navigate('/agents/list')} 
        />
      </div>
    </div>
  );
};

export default AddAgentPage;
