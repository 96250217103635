import TableAgent from '@/components/agents/TableAgent'
import React from 'react'

const AgentsListPage = () => {
  return (
    <div className='bg-gray-100'>
      <div className='bg-white text-center w-full py-5 rounded-lg'>
				<h1 className='text-2xl font-bold'>Daftar Agent</h1>
			</div>

      <TableAgent />
    </div>
  )
}

export default AgentsListPage