import MessageError from '@/components/form/MessageError';

export const messageError = (error) => {
	if (!error) {
		return '';
	}

	if (typeof error === 'string') {
		return error;
	}

	if (error?.message && typeof error.message === 'string') {
		return error.message;
	}

	return '';
};

const TextInput = ({
	label,
	labelClass = '',
	error,
	className = '',
	children,
}) => {
	return (
		<div className={`input-field ${className}`}>
			{label ? <label className={`text-sm font-semibold ml-1 mb-2 ${labelClass}`}>{label}</label> : null}
			{children}
			{error ? <MessageError error={messageError(error)} /> : null}
		</div>
	);
};

export const TextInputDefault = ({
	label,
	labelClass = '',
	error,
	className = '',
	value,
	type = "text",
	name,
	placeholder,
	onChange,
	disabled,
}) => {
	return (
		<div className={`input-field ${className}`}>
			{label ? <label className={`text-sm font-semibold ml-1 mb-1 ${labelClass}`}>{label}</label> : null}
			<input
				name={name}
				value={value}
				type={type}
				style={{
					borderWidth: error ? "1px" : "1px",
					padding: "18px",
					borderRadius: "10px",
					height: 50,
				}}
				disabled={disabled}
				placeholder={placeholder}
				className={`text-black ${error ? "border-red-500" : "border-gray-300"}`}
				onChange={onChange}
			/>
			{error ? <MessageError error={messageError(error)} /> : null}
		</div>
	)
};

export default TextInput;
