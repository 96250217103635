import { VerificationFormAgent } from "@/components/agents/FormAgent";
import Loading from "@/components/common/Loading";
import Notification from "@/components/common/Notification";
import { API_URL } from "@/redux-client/api";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const AgentVerificationPage = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

	const [dataAgent, setDataAgent] = useState(null);
	const [pageLoading, setPageLoading] = useState(false);
	const [pageNotification, setPageNotification] = useState(null);

	const fetchSingleAgent = useCallback(async () => {
		setPageLoading(true);

		try {
			const response = await axios.get(`${API_URL}/agent/${state._id}`);
			if (response.status === 200) {
				const { data } = response.data;
				setDataAgent(data);
				setPageLoading(false);
			}
		} catch (error) {
			if (error?.response?.data) {
				const { message } = error.response.data;
				setPageNotification({
					isError: true,
					title: 'Error',
					text: message,
				});
			}

			setPageLoading(false);

			setTimeout(() => {
				setPageNotification(null);
			}, 2000);
		}
	}, [state]);

	useEffect(() => {
		fetchSingleAgent();
	}, [fetchSingleAgent]);

  return (
    <div>
      <div className="bg-white text-center w-full py-5 rounded-lg relative">
        <h1 className="text-2xl font-bold">Verifikasi Agent</h1>
        <button
          onClick={() => navigate("/agents/list")}
          className="absolute left-[10px] top-[29%]"
        >
          <IoChevronBack size={30} />
        </button>
      </div>
      <Notification notification={pageNotification} />
      <div className="bg-white p-5 mt-10">
      {pageLoading ? (
					<div className='py-10'>
						<Loading
							type='beat-loader'
							loading={pageLoading}
							size={24}
						/>
					</div>
				) : (
          <VerificationFormAgent
            data={dataAgent}
            admin_id={user._id}
						className='sm:grid-cols-3'
            closeModalHandler={() => navigate("/agents/list")}
          />
        )}
      </div>
    </div>
  );
};

export default AgentVerificationPage;
