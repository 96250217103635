import React from 'react'

const DataTextDisplay = ({ label, value, className = '' }) => {
  return (
    <div className={className}>
      {label && <label className="text-sm">{label}</label>}
      <p className="text-md mt-1 font-semibold">{value}</p>
    </div>
  );
};

export default DataTextDisplay