export const adminRoleOptions = [
	{ value: 'super-admin', label: 'Super Admin' },
	{ value: 'admin', label: 'Admin' }
];

export const agentStatusOptions = [
	{ value: "new", label: 'Belum Terverifikasi' },
	{ value: "verified", label: 'Terverfikasi' },
	{ value: "blocked", label: 'Diblokir' }
];

export const agentBookingStatusOptions = [
  {
    label: "Negosiasi",
    value: "negosiasi",
  },
  {
    label: "DP",
    value: "dp",
  },
  {
    label: "Lunas",
    value: "settle",
  },
  {
    label: "Batal",
    value: "cancel",
  },
];