import { HiOutlineSave } from 'react-icons/hi';
import { IoTrashOutline } from 'react-icons/io5';
import { MoonLoader } from 'react-spinners';
import Button from './Button';

// type Props = {
// 	loading: boolean;
// 	hasCancel?: boolean;
// 	isSubmitDelete?: boolean;
// 	submitLabel?: ReactNode;
// 	onSubmit: () => void;
// 	onCancel?: () => void;
// 	className?: string;
// };

const ModalFooterButton = ({
	loading = true,
	hasCancel = true,
	isSubmitDelete,
	submitLabel,
	cancelLabel,
	onSubmit,
	onCancel,
	className = '',
}) => {
	return (
		<div className={`flex justify-between mt-10 ${className}`}>
			{loading ? (
				<div className='px-4 ml-auto'>
					<MoonLoader
						loading={loading}
						size={24}
					/>
				</div>
			) : (
				<>
					{typeof onCancel === 'function' && hasCancel ? (
						<Button
							buttonType='error-outline'
							rounded={false}
							onClick={onCancel}
						>
							{cancelLabel ? cancelLabel : "Batal"}
						</Button>
					) : null}
					<Button
						type='submit'
						buttonType={isSubmitDelete ? 'error' : 'primary'}
						rounded={false}
						onClick={onSubmit}
						parentClass='ml-auto'
					>
						{isSubmitDelete ? (
							<IoTrashOutline
								size={20}
								className='mr-2'
							/>
						) : (
							<HiOutlineSave
								size={20}
								className='mr-2'
							/>
						)}
						{submitLabel ? submitLabel : 'Simpan'}
					</Button>
				</>
			)}
		</div>
	);
};

export default ModalFooterButton;
