import TableTransactionHistory from "@/components/agents/TableTransactionsHistory";
import React from "react";
import { HiChevronLeft } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

const AgentComissionsDetailsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100">
      <div className="bg-white text-center w-full py-5 rounded-lg relative">
        <div className="absolute ml-3">
          <button
            onClick={() => navigate("/agents/comission")}
            className="flex items-center rounded-xl text-black opacity-80 hover:opacity-100 transition-all ease-in-out duration-300"
          >
            <HiChevronLeft size={20} />
            Kembali
          </button>
        </div>
        <h1 className="text-2xl font-bold">Detail Transaksi Komisi</h1>
      </div>

      <TableTransactionHistory agentId={state._id} />
    </div>
  );
};

export default AgentComissionsDetailsPage;
