import axios from 'axios';
import { IoAdd } from 'react-icons/io5';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HiPencilAlt } from 'react-icons/hi';

import { AddFormAgent } from '../agents/FormAgent';

import { agentStatusOptions } from '@/static/data';
import { API_URL } from '@/redux-client/api';

import CustomTable from '@/components/table/CustomTable';
import TableFilterSelect from '@/components/common/TableFilterSelect';
import Notification from '@/components/common/Notification';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

const TableAlert = ({
  totalNewAgent,
  onShowNewAgentList
}) => {
  return (
    <div className='bg-orange-500 p-4 rounded-xl mt-8 flex items-center gap-2'>
      <p className='text-white'><strong>{totalNewAgent} permintaan</strong> agent baru belum diverifikasi.</p>
      <button onClick={onShowNewAgentList}>
        <span className='underline text-white'>Lihat Permintaan</span>
      </button>
    </div>
  )
};

const TableHeader = ({
  navigate,
  totalAgents,
  openAddModalHandler,
  totalNewAgent,
  onShowNewAgentList,
}) => {
  return (
    <div>
      { totalNewAgent > 0 && 
        <TableAlert totalNewAgent={totalNewAgent} onShowNewAgentList={onShowNewAgentList} />
      }
      <div className="flex items-center justify-between mt-6">
        <p className="mb-0 text-black">
          Total : <strong>{totalAgents}</strong> agent
        </p>
        <Button onClick={() => navigate('/agents/add')}>
          <i className="mr-2">
            <IoAdd />
          </i>
          <span>Tambah Agent</span>
        </Button>
      </div>
    </div>
  );
};

const TableAgent = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);
	const [pages, setPages] = useState(0);
	const [data, setData] = useState([]);
	const [totalAgents, setTotalAgents] = useState(0);
	const [totalNewAgents, setTotalNewAgents] = useState(0);
	const [tableKey, setTableKey] = useState(null);
	const [isAddAgentModalOpen, setIsAddAgentModalOpen] = useState(false);
	const [isDeleteAgentModalOpen, setIsDeleteAgentModalOpen] = useState(false);
	const [selectedAgent, setSelectedAgent] = useState(null);
  
  const tableRef = useRef(null);
  const scrollToTable = () => tableRef.current.scrollIntoView();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setTableKey(Date.now());

		return () => setTableKey(Date.now());
	}, []);

	const fetchAgents = useCallback(async (page, filtered, sorted, pageSize) => {
		setIsLoading(true);
		const payload = {
			pagination: {
				limit: pageSize,
				activePage: page,
			},
			sort: sorted,
			filters: filtered,
		};

		try {
			const response = await axios.post(`${API_URL}/agent/list`, payload);

			if (response.status === 200) {
				const { data, totalData, totalPage, totalNewAgent } = response.data.message;
				setData(data);
				setPages(totalPage);
				setTotalAgents(totalData);
        setTotalNewAgents(totalNewAgent)
				setIsLoading(false);
			} else {
				const { statusText } = response;
				setNotification({
					title: statusText,
					text: 'Please try again later.',
					isError: true,
				});
			}
		} catch (error) {
			console.log(error);
			setNotification({
				title: 'Network error!',
				text: 'Please try again later.',
				isError: true,
			});
		}
	}, []);

	const onCloseModal = useCallback(() => {
		setIsDeleteAgentModalOpen(false);
		setIsAddAgentModalOpen(false);
		fetchAgents(0, [], [], 20);
	}, [fetchAgents]);

	const onDeleteAgent = useCallback(async() => {
		try {
			setIsLoading(true);
			const response = await axios.delete(`${API_URL}/agent/${selectedAgent._id}`);
			if (response.status === 200) {
				const { message } = response.data;
				onCloseModal();
				setNotification({
					isError: false,
					title: 'Sukses',
					text: message,
				});

				setIsLoading(false);

				setTimeout(() => {
					setNotification(null);
				}, 2000);
			}
		} catch (error) {
			console.log('error', error);
			onCloseModal();
			if (error?.response?.data) {
				const { message } = error.response.data;
				setNotification({
					isError: true,
					title: 'Error',
					text: message,
				});
			}

			setIsLoading(false);

			setTimeout(() => {
				setNotification(null);
			}, 2000);
		}
	}, [onCloseModal, selectedAgent]);

	const tableColumns = useMemo(
		() => [
			{
				Header: 'Nama',
				accessor: 'name',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{value}</p>;
				},
			},
			{
				Header: 'Email',
				accessor: 'email',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{value}</p>;
				},
			},
			{
				Header: 'No. HP',
				accessor: 'phone',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 fs-14'>{value}</p>;
				},
			},
			{
				Header: 'Status',
				accessor: 'status',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return (
            <p 
              className='m-0 fs-14 capitalize text-sm py-2 px-4 rounded-full '
              style={{
                backgroundColor: value === "new" ? "#FFDE21" : value === "verified" ? "#19bf2f" : "#950606",
                color: value === "new" || value === "blocked" ? "#000000" : "#FFFFFF",
              }}
            >
              {value === 'new' ? 'Belum Terverifikasi' : value === "blocked" ? "Diblokir" : "Terverifikasi"}
            </p>  
          );
				},
				Filter: ({ filter, onChange }) => (
					<TableFilterSelect
						filter={filter?.value}
						onChange={onChange}
						options={[{ value: 'ALL', label: 'Semua' }, ...agentStatusOptions]}
					/>
				),
			},
			{
				Header: '',
				accessor: '_id',
				minWidth: 180,
				filterable: false,
				sortable: false,
				Cell: ({ row }) => {
          const path = row._original.status === "new" ? "verification" : "details";
          return (
					<div className='flex items-center justify-between'>
						<Button
							buttonType='info'
							buttonSize='sm'
							rounded={false}
							onClick={() =>
								navigate(`${pathname}/${path}`, {
									state: row._original,
								})
							}
						>
							<HiPencilAlt
								size={14}
								className='mr-1'
							/>
              {row._original.status === "new" ? "Verifikasi Akun" : "Detail & Edit"}
						</Button>
					</div>
				)},
				style: { textAlign: 'center' },
			},
		],
		[navigate, pathname]
	);

	return (
		<>
			<Notification notification={notification} />
			<CustomTable
        ref={tableRef}
				wrapperClassName='p-4'
				pages={pages}
				tableKey={tableKey}
				isLoading={isLoading}
				data={data}
				columns={tableColumns}
				onFetchData={({ page, filtered, sorted, pageSize }) => {
					fetchAgents(page, filtered, sorted, pageSize);
				}}
				showTableHeader={true}
				headerComponent={
          <TableHeader
            navigate={navigate}
            totalAgents={totalAgents}
            openAddModalHandler={() => setIsAddAgentModalOpen(true)}
            totalNewAgent={totalNewAgents} 
            onShowNewAgentList={() => {
              fetchAgents(0, [{
                id: 'status',
                value: { value: "new", label: "Belum Terverifikasi" }
              }], [], 20);

              if(tableRef && tableRef.current) {
                scrollToTable();
              }
            }}
          />
				}
			/>
			<Modal
				title='Tambah Agent'
				isOpen={isAddAgentModalOpen}
				showCloseButton={true}
				onClose={onCloseModal}
			>
				<AddFormAgent closeModalHandler={onCloseModal} />
			</Modal>
		</>
	);
};

export default TableAgent;
