import axios from 'axios';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HiPencilAlt } from 'react-icons/hi';

import { AddFormAgent } from './FormAgent';

import { agentBookingStatusOptions } from '@/static/data';
import { API_URL } from '@/redux-client/api';

import CustomTable from '@/components/table/CustomTable';
import TableFilterSelect from '@/components/common/TableFilterSelect';
import Notification from '@/components/common/Notification';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

const TableHeader = ({
  totalSales,
}) => {
  return (
    <div>
      <div className="flex items-center justify-end mt-6">
        <p className="mb-0 text-black">
          Total : <strong>{totalSales}</strong> sales
        </p>
      </div>
    </div>
  );
};

const TableSalesAgent = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState(null);
	const [pages, setPages] = useState(0);
	const [data, setData] = useState([]);
	const [totalSales, settotalSales] = useState(0);
	const [totalNewAgents, setTotalNewAgents] = useState(0);
	const [tableKey, setTableKey] = useState(null);
	const [isAddAgentModalOpen, setIsAddAgentModalOpen] = useState(false);
  
  const tableRef = useRef(null);
  const scrollToTable = () => tableRef.current.scrollIntoView();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setTableKey(Date.now());

		return () => setTableKey(Date.now());
	}, []);

	const fetchAgents = useCallback(async (page, filtered, sorted, pageSize) => {
		setIsLoading(true);
		const payload = {
			pagination: {
				limit: pageSize,
				activePage: page,
			},
			sort: sorted,
			filters: filtered,
		};

		try {
			const response = await axios.post(`${API_URL}/booking/agent-sales`, payload);

			if (response.status === 200) {
				const { data, totalData, totalPage, totalNewAgent } = response.data.message;
				setData(data);
				setPages(totalPage);
				settotalSales(totalData);
        setTotalNewAgents(totalNewAgent)
				setIsLoading(false);
			} else {
				const { statusText } = response;
				setNotification({
					title: statusText,
					text: 'Please try again later.',
					isError: true,
				});
			}
		} catch (error) {
			console.log(error);
			setNotification({
				title: 'Network error!',
				text: 'Please try again later.',
				isError: true,
			});
		}
	}, []);

	const onCloseModal = useCallback(() => {
		setIsAddAgentModalOpen(false);
		fetchAgents(0, [], [], 20);
	}, [fetchAgents]);

	const tableColumns = useMemo(
		() => [
			{
				Header: 'Booking ID',
				accessor: 'booking_code',
				minWidth: 160,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm'>{value}</p>;
				},
			},
			{
				Header: 'Nama Agen',
				accessor: 'agent_id.name',
				minWidth: 200,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm'>{value}</p>;
				},
			},
			{
				Header: 'Tanggal Acara',
				accessor: 'event_data.event_date',
				minWidth: 140,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm'>{value}</p>;
				},
			},
			{
				Header: 'Lokasi Acara',
				accessor: 'event_data.event_location',
				minWidth: 240,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return <p className='m-0 text-sm'>{value}</p>;
				},
			},
			{
				Header: 'Status Pesanan',
				accessor: 'status',
				minWidth: 150,
				textAlign: 'left',
				sortable: false,
				Cell: ({ value }) => {
					return (
            <p 
              className='m-0 text-sm capitalize py-2 px-4 rounded-full '
              style={{
                backgroundColor: value === "negosiasi" ? "#FFDE21" : value === "dp" ? '#008080' : value === "settle" ? "#19bf2f" : "#d60200",
                color: value === "cancel" || value === 'dp' || value === 'settle' ? "#FFFFFF" : "#000000",
              }}
            >
              {value === 'cancel' ? "Batal" : value === 'dp' ? 'DP' : value}
            </p>  
          );
				},
				Filter: ({ filter, onChange }) => (
					<TableFilterSelect
						filter={filter?.value}
						onChange={onChange}
						options={[{ value: 'ALL', label: 'Semua' }, ...agentBookingStatusOptions]}
					/>
				),
			},
			{
				Header: '',
				accessor: '_id',
				minWidth: 180,
				filterable: false,
				sortable: false,
				Cell: ({ row }) => {
          return (
						<div className='flex items-center justify-between'>
							<Button
								buttonType='info'
								buttonSize='sm'
								rounded={false}
								onClick={() =>
									navigate(`${pathname}/details`, {
										state: row._original,
									})
								}
							>
								<HiPencilAlt
									size={14}
									className='mr-1'
								/>
								{row.status === "settle" || row.status === "cancel"  ? "Detail" : "Konfirmasi"}
							</Button>
						</div>
					)
				},
				style: { textAlign: 'center' },
			},
		],
		[navigate, pathname]
	);

	return (
		<>
			<Notification notification={notification} />
			<CustomTable
        ref={tableRef}
				wrapperClassName='p-4'
				pages={pages}
				tableKey={tableKey}
				isLoading={isLoading}
				data={data}
				columns={tableColumns}
				onFetchData={({ page, filtered, sorted, pageSize }) => {
					fetchAgents(page, filtered, sorted, pageSize);
				}}
				showTableHeader={true}
				headerComponent={
          <TableHeader
            navigate={navigate}
            totalSales={totalSales}
            openAddModalHandler={() => setIsAddAgentModalOpen(true)}
            totalNewAgent={totalNewAgents} 
            onShowNewAgentList={() => {
              fetchAgents(0, [{
                id: 'status',
                value: { value: "new", label: "Belum Terverifikasi" }
              }], [], 20);

              if(tableRef && tableRef.current) {
                scrollToTable();
              }
            }}
          />
				}
			/>
			<Modal
				title='Tambah Agent'
				isOpen={isAddAgentModalOpen}
				showCloseButton={true}
				onClose={onCloseModal}
			>
				<AddFormAgent closeModalHandler={onCloseModal} />
			</Modal>
		</>
	);
};

export default TableSalesAgent;
