import { Navigate, Route, Routes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import MainLayout from "@/components/layout";
import LoginPage from "@/pages/LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import DashboardPage from "./pages/DashboardPage";
import NegotiationBooking from "./pages/NegotiationBooking";
import SettingsPage from "./pages/SettingsPage";
import BookingPage from "./pages/BookingPage";
import AdminPage from "./pages/AdminPage";
import AdminDetailsPage from "./pages/AdminDetailsPage";
import NegotiationBookingDetailsPage from "./pages/NegotiationBookingDetailsPage";
import NegotiationConfirmation from "./pages/NegotiationConfirmation";
import BookingDetailsPage from "./pages/BookingDetailsPage";
import PackagePage from "./pages/PackagePage";
import PackageDetailsPage from "./pages/PackageDetailsPage";
import AgentsPage from "./pages/agents/AgentsPage";
import AgentsListPage from "./pages/agents/AgentsListPage";
import AgentDetailsPage from "./pages/agents/AgentDetailsPage";
import AgentVerificationPage from "./pages/agents/AgentVerificationPage";
import AddAgentPage from "./pages/agents/AddAgentPage";
import AgentSalesPage from "./pages/agentSales/agentSalesPage";
import AgentComissionsPage from "./pages/agentComission/agentComissions";
import AgentSalesDetailsPage from "./pages/agentSales/agentSalesDetailsPage";
import AgentComissionsDetailsPage from "./pages/agentComission/agentComissionDetails";

export default function App() {

  const { user, isAuthenticated } = useSelector((state) => state.auth);

	const homeRoute = useMemo(() => {
		let pathRoute = '/dashboard';
		if (isAuthenticated) {
			const { role } = user;
			if (
				role === "admin" || role === "super-admin"
			) {
				pathRoute = '/dashboard';
			}
		}
		return pathRoute;
	}, [isAuthenticated, user]);

  return (
    <MainLayout>
      <Routes>
        <Route
          path="/login"
          element={
            <AuthRoute>
              <LoginPage />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jadwal-negosiasi"
          element={
            <ProtectedRoute>
              <NegotiationBooking />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jadwal-negosiasi/:id"
          element={
            <ProtectedRoute>
              <NegotiationBookingDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/negotiation-confirmation"
          element={
            <ProtectedRoute>
              <NegotiationConfirmation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bookings"
          element={
            <ProtectedRoute>
              <BookingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bookings/details"
          element={
            <ProtectedRoute>
              <BookingDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/packages"
          element={
            <ProtectedRoute>
              <PackagePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/packages/details"
          element={
            <ProtectedRoute>
              <PackageDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admins"
          element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admins/details"
          element={
            <ProtectedRoute>
              <AdminDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents"
          element={
            <ProtectedRoute>
              <AgentsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/add"
          element={
            <ProtectedRoute>
              <AddAgentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/list"
          element={
            <ProtectedRoute>
              <AgentsListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/list/verification"
          element={
            <ProtectedRoute>
              <AgentVerificationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/list/details"
          element={
            <ProtectedRoute>
              <AgentDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/sales"
          element={
            <ProtectedRoute>
              <AgentSalesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/sales/details"
          element={
            <ProtectedRoute>
              <AgentSalesDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/comission"
          element={
            <ProtectedRoute>
              <AgentComissionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agents/comission/details"
          element={
            <ProtectedRoute>
              <AgentComissionsDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to={homeRoute} replace />} />
      </Routes>
    </MainLayout>
  );
}